@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');



/* Estilos para a navbar fixa */
.navbar {
  position: fixed;
  width: 100%;
  background-color: #ffffff; /* Cor de fundo da navbar */
  display: flex;
  justify-content: space-between; /* Espaço entre o logo e os itens do menu */
  align-items: baseline; /* Centraliza verticalmente os itens */
  z-index: 1000; /* Garante que a navbar fique acima de outros conteúdos */
  margin: 0 auto;
  padding: 2em 0 0.5em 0; /* Ajuste conforme necessário */
  border-bottom: 1px solid black;
}

/* Estilos para o logo */
.navbar-logo img {
  width: 100%;
}

.navbar-logo {
  width: 12%;
  margin-left: 10%;
}

.navbar-menu {
  margin-right: 10%;
}

/* Estilos para o menu de navegação */
.navbar-menu ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0; /* Remova qualquer padding */
  
}

.navbar-menu li {
  margin: 0; /* Espaçamento entre os itens do menu */
  padding: 0; /* Remova qualquer padding */
  
}

.navbar-menu a {
  display: block; /* Certifique-se de que o link ocupe todo o espaço do li */
  padding: 10px 0px 2px 60px; /* Ajuste conforme necessário */
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  color: #6c6c6c; /* Ajuste conforme necessário */
  
}

@media (min-width: 768px) and (max-width: 1024px) {
  .navbar-logo {
    width: 21%;
  }


  .navbar-menu a {
    padding: 10px 0px 2px 50px; /* Ajuste conforme necessário */
  }
}

@media (max-width: 767px) {
  .navbar {
    flex-direction: column;
    align-items: center; /* Certifique-se de que os itens estão alinhados ao centro */
  }

  .navbar-logo {
    margin: 0 auto;
    width: 30%;
  }

  .navbar-menu {
    margin: 0 auto;
    width: 100%;
    justify-content: center;
  }

  .navbar-menu ul {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    
  }

  .navbar-menu li {
    margin: 0 5px; /* Ajuste o espaçamento conforme necessário */
  }

  .navbar-menu a {
    font-size: 17px; /* Ajuste conforme necessário */
  }

  .navbar-menu a {
    padding: 20px 10px 2px 10px; /* Ajuste conforme necessário */
    
  }
}

/* NavbarServices */
.nav-bar-services {
  background-color: #ffffff;
  height: 13%;
  position: fixed;
  width: 100%;
  align-items: center; /* Centraliza verticalmente */
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  top: 0;
  z-index: 1000; /* Garante que a navbar fique acima de outros conteúdos */
  border-bottom: 0.09em solid #6c6c6c;
}

.nav_logo {
  width: 12%;
  display: block;
  margin: 0 auto;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .nav_logo {
    width: 21%;
  }
}

@media (max-width: 767px) {
  .nav_logo {
    width: 30%;
  }
}
