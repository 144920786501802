@import url('https://fonts.cdnfonts.com/css/tt-backwards-sans-trial');
@import url('https://fonts.cdnfonts.com/css/tt-commons');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.container-1{
    height: calc(100vh - 11.7em);
    padding-top: 11.7em;
    background-color: #ffffff;
  }

  .container-3{
    padding-top: 3em;
    height: calc(100vh - 11em);
    background-color: #ffffff;
    scroll-margin-top: 8em;
  }
  
  .container-2 {
    height: calc(100vh - 8em);
    background-color: #ffffff;
    scroll-margin-top: 10em;
  }

  .container-4{
    height: calc(100vh - 11em);
    padding-top: 3em;
    background-color: #ffffff;
    scroll-margin-top: 8em;
  }
  
  .margens{
    /*border: 1px solid black;*/
    margin: 0 10% 0 10%;
    height: 90%;
  }
  
  
  /*SERVICES*/
  /*Trocar Tipo de letra, colocar imagens corretas, media queries para colocar em row quando ecran pequeno*/
  
  .services-cards-wrapper{
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 3% 4% 4% 0;
  }
  
  .service-card{
    height: 100%;
    width: 32%;
    font-family: 'TT Commons Pro', sans-serif;
    color: #6c6c6c;
    font-size: 25px;
    text-align: center;
  }

  .service-card b{
    line-height: 2;
    font-weight: 500;
  }

  .service-card p{
    line-height: 0.7;
  }
  
  .card-img{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .card-description{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    display: none;
    font-family: "Open Sans", sans-serif;
  }

  .card-img:hover .card-description {
    display: flex;
    cursor: pointer;
    transition: .5s;
    scale: 1.2;
  }

  .card-img:hover{
    transition: .5s;
    scale: 1.04;
  }

  #img-textile{
    background-image: url('../../assets/images/textile.jpg');
    background-size: cover; /* ou contain, dependendo do resultado desejado */
    background-repeat: no-repeat; /* evita que a imagem se repita */
    background-position: center; /* centraliza a imagem */
    /*colocar o alt e loading lazy*/
  }

  #img-labelpack{
    background-image: url('../../assets/images/label_pack.jpg');
    background-size: cover; /* ou contain, dependendo do resultado desejado */
    background-repeat: no-repeat; /* evita que a imagem se repita */
    background-position: center; /* centraliza a imagem */
    /*colocar o alt e loading lazy*/
  }

  #img-sourcing{
    background-image: url('../../assets/images/sourcing.jpg');
    background-size: cover; /* ou contain, dependendo do resultado desejado */
    background-repeat: no-repeat; /* evita que a imagem se repita */
    background-position: center; /* centraliza a imagem */
    /*colocar o alt e loading lazy*/
  }  

  @media (max-width: 1024px) {

    .container-2{
      height: 100%;
    }

    .services-cards-wrapper{
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
    }

    .service-card{
      width: 100%;
      height: auto;
      padding: 0.6em;
    }

    #img-textile{
      background-image: url('../../assets/images/textileM.jpg');      
    }
  
    #img-labelpack{
      background-image: url('../../assets/images/label_packM.jpg');
    }
  
    #img-sourcing{
      background-image: url('../../assets/images/sourcingM.jpg');
    } 
  }

  @media (min-width: 768px) and (max-width: 1024px) {
  
  .card-img{
      height: 500px;
    }   
  }

  @media (max-width: 767px) {

    .service-card{
      font-size: 20px;
    }

    .margens{
      /*border: 1px solid black;*/
      margin: 0 5% 0 5%;
    }

    .card-img{
      height: 340px;
    } 

  }
  
  /*ABOUT US*/
  
  .container-3 > .margens{
    display: flex;
    flex-direction: row;
  }
  
  .about-us-left{
    width: 100%;
    height: 100%;
  }
  
  .about-us-right{
    width: 100%;
    height: 100%;
  }
  
  .img-empresa{
    height:500px;
    width: 500px;
    float: right;
  }
  
  .about-us-text{
    font-family: 'TT Commons Pro', sans-serif;
    font-size: 20px;
    line-height: 1.8;
    margin-top: 30px;
    color: #6c6c6c;
    text-align: justify;
  }
  
  .about-us{
    font-family: 'TT Backwards Sans Trial', sans-serif;
    font-size: 72px;
    color: #6c6c6c;
  }
  
  .about-us-pontos-fortes{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 150px;
  }
  
  .icon{
    width: 50px;
    align-items: center;
  }
  
  .icon-text > p{
    text-align: center;
    font-family: 'TT Commons Pro', sans-serif;
    font-size: 15px;
    color: #6c6c6c;
  }

  .icon-text {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  /*.button-see-more{
    margin-top: 0;
    background-color: white;
    color: #6c6c6c;
    border: 2px solid #6c6c6c;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 10px 0;
    transition-duration: 0.4s;
    cursor: pointer;
    float: right;
  }
  
  .button-see-more:hover{
    background-color: #e6e6e6;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.24), 0 2px 2px 0 rgba(0,0,0,0.19);
  }*/

  .container-name {
    margin: 40px 0 0 0;
    float: right;
  }
  
  .example_f {
    border-radius: 4px;
    background-color: #e6e6e6;
    border: none;
    color: #6c6c6c;
    text-align: center;
    font-family: 'TT Commons Pro', sans-serif;
    font-size: 16px;
    padding: 16px;
    transition: all 0.4s;
    cursor: pointer;
  }
  
  .example_f span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
  }
  
  .example_f span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  
  .example_f:hover span {
    padding-right: 25px;
  }
  
  .example_f:hover span:after {
    opacity: 1;
    right: 0;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    
    .about-us{
      font-size: 60px;
    }
    
    .about-us-left{
      width: 85%;
    }
    
    .about-us-right{
      width: 115%;
    }
    .img-empresa{
      margin-top: 110px;
      height:calc(100% - 95px);
      width: 90%;
      float: right;
    }  

    .icon{
      width: 50px;
      align-items: center;
    }

    .about-us-pontos-fortes{
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      width: 100%;
      margin-top: 150px;
    }

    .icon-text {
      width: 50%;
    }
  }

  @media (max-width: 767px) {

    .container-3{
      height: 100%;
    }
    .container-3 > .margens{
      display: flex;
      flex-direction: column;
    }
  
    .about-us-right{
      display: flex;
      align-items: center;
      margin-bottom: 7%;
    }
  
    .about-us-left{
      align-items: center;
    }
  
    .icon-text{
      padding: 0.4em;
    }

    .img-empresa{
      width: 100%;
    }

    .about-us-text{
      font-size: 17px;
    }

    .about-us{
      font-size: 45px;
    }

    .about-us-pontos-fortes{
      margin-top: 105px;
    }

    .container-name {
      margin: 25px 0 0 0;
      float: right;
    }

    .icon{
      width: 40px;
      align-items: center;
    }
    
  }
  
  /*CERTIFICATIONS
  
  .certifications{ 
    font-family: 'TT Backwards', sans-serif;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 50px;
    color: #6c6c6c;
    border:2px solid #ffffff;
    margin-bottom: 0;
    text-align: center;
  }
  
  .certifications p{
    margin: 1.8em 0 0 0;
  }

  .certificado{
    height: 80px;
    margin: 50px;
  }*/
  
  /*CONTACTS*/
  
  .contacts{ 
    font-family: 'TT Backwards Sans Trial', sans-serif;
    font-size: 72px;
    color: #6c6c6c;
    border:2px solid #ffffff;
    margin-bottom: 0;
  }
  
  .contacts p{
    margin: 0 0 20px 0;
  }
  
  .contacts-main{
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  
  .contacts-esq {
    width: 65%;
  }
  
  .contacts-dir {
    font-family: 'TT Commons Pro', sans-serif;
    color: #6c6c6c;
    font-size: 20px;
    width: 35%;
  }
  
  .contacts-dir h2 {
    font-family: 'TT Commons Pro', sans-serif;
    color: #6c6c6c;
    font-size: 17px;
    font-weight: 700;
    margin: 0 0 0 0;
  }
  .contacts-dir p {
    margin-bottom: 28px;
  }
  
  .social-media{
    width: 90px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .icon-social{
    width: 40px;
  }

  .icon-social:hover{
    cursor: pointer;
    filter: opacity(21%);
  }

  .mapa{
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1024px) {
    .contacts-main{
      display: flex;
      flex-direction: column;
    } 

    .contacts-esq{
      width: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 7%;      
    }

    .mapa{
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {

    .contacts{ 
      font-size: 60px;
    }

    .cont{
      display: flex;
      flex-direction: column;
      width: 50%;
      align-items: center;
    }

    .contacts-dir{
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      text-align: center;
      align-items: center;
    }

    .mapa{
      padding: 20px 0 20px 0;
    }
  }
  
  @media (max-width: 767px) { 
  
    .container-4{
      height: 100%;
    }

    .contacts-dir{
      margin-bottom: 2em;
    }
    
    .contacts-dir h2 {
      font-size: 15px;
    }
  
    .contacts{ 
      font-size: 45px;
    }

    .contacts-dir{
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      font-size: 17px;
    }
  }

  /*FOOTER*/
  
  .footer{
    background-color: #e6e6e6;
    overflow: hidden;
  }

  .certifications{ 
    font-family: 'TT Backwards', sans-serif;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 25px;
    color: #6c6c6c;
    margin-bottom: 0;
    text-align: center;
  }

  .row1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .site-links a{
    margin: 5px 0 0 0;
    text-decoration: none;
    color: #6c6c6c;

  }

  .site-links a:hover{
    cursor: pointer;
    font-weight: bold;
  }

  .site-links h4{
    margin: 0 0 5px 0;
  }

  .certificado{
    height: 80px;
    margin: 20px;
  }
  
  .row2{
    text-align: center;
  }
  
  .páginas {
    font-family: 'TT Commons Pro', sans-serif;
    color: #6c6c6c;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @media (max-width: 1024px) {

    .páginas {
      text-align: center;
    }

    .site-links{
      display: none;
    }

  }

  /*Remover barra de scroll*/
  body::-webkit-scrollbar{
    display: none;
  }

  html{
    scroll-behavior: smooth;
  }



  @media (min-width: 1540px) {
    .services-cards-wrapper{
      padding-top: 1.5em;
    }

    .img-empresa{
      height: 650px;
      width: 650px;
      float: right;
    }

    .icon{
      width: 70px;
      padding-top: 2em;
      
    }

    .about-us-text{
      font-family: 'TT Commons Pro', sans-serif;
      font-size: 20px;
      line-height: 2.2;
      margin-top: 30px;
      color: #6c6c6c;
      text-align: justify;
    }
    
    .about-us{
      font-family: 'TT Backwards Sans Trial', sans-serif;
      font-size: 80px;
      color: #6c6c6c;
    }
    
  }