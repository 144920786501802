.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
  }
  
  .close-button {
    position: absolute;
    background-color: black;
    color: whitesmoke;
    border-radius: 50%;
    right: -15px;
    top: -25px;
    width: 8%;
    height: 8%;
    font-size: 35px;
    text-align: center;
    margin:0;
    cursor: pointer;
  }
  
  .modal-image {
    max-width: 90vw;
    max-height: 80vh;
  }
  

  @media (max-width: 600px) {
    .close-button {
        font-size: 27px;
    }
  }

  @media (min-width: 1540px) {
    .close-button {
      font-size: 40px;
  }

    
  }