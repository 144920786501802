

  .Caroussel{
    width: 100%;
    height: 100%;
  }

  .container1{
    background-image: url('../../assets/images/carrosel-img1.jpg');
    width: 100%;
    height: 100%;
    background-size: cover; /* ou contain, dependendo do resultado desejado */
    background-repeat: no-repeat; /* evita que a imagem se repita */
    background-position: center; /* centraliza a imagem */
  }

  /* Override default dots with rectangles */
.carousel .control-dots .dot {
  width: 55px !important ;/* Set the width of the rectangle */
  height: 5px !important; /* Set the height of the rectangle */
  border-radius: 0 !important;
  background: #fff !important; /* Default background color */
  margin: 0 15px !important; /* Adjust spacing between rectangles */
  transition: opacity 0.25s ease-in !important; /* Smooth transition */
}

.carousel .control-dots .dot.selected {
  background: #6c6c6c; /* Background color for the active indicator */
}


  @media (max-width: 1024px) {
    .container {
      /*background-image: url('../../assets/images/carrosel-img1M.jpg');*/
      position: relative;
      width: 100%;
    }

    #img1{
      content: url('../../assets/images/carrosel-img1M.jpg');
    }

    #img2{
      content: url('../../assets/images/carrosel-img2M.jpg');
    }
  }
  
  @media (max-width: 767px) {
    .Caroussel{
      width: 100%;
      height: 100%;
    }
  }
  