.AboutUs_Title {
    font-family: 'TT Backwards Sans Trial', sans-serif;
    font-size: 72px;
    color: #6c6c6c;
    font-weight: 400;
    margin-top: 0;
    }

    .AboutUs_Desc{
        color: #6c6c6c;
        font-family: 'TT Commons Pro', sans-serif;
        font-size: 20px;
        line-height: 1.8;
        margin-top: 30px;
        color: #6c6c6c;
        text-align: justify;
    }

    .container-aboutus{
        padding-top: 6em;
        height: calc(100vh - 6em);
        background-image: url('../../assets/images/fusion-aboutus.jpg');
        background-size: cover; /* ou contain, dependendo do resultado desejado */
        background-repeat: no-repeat; /* evita que a imagem se repita */
        background-position: center; /* centraliza a imagem */
        /*colocar o alt e loading lazy*/
    }

    @media (max-width: 1024px) {
        .container-aboutus{
            padding-top: 5em;
            width: 100%;
            height: calc(100vh - 5em);
            background-image: url('../../assets/images/fusion-aboutusM.jpg');
        }
    }
    
    @media (min-width: 768px) and (max-width: 1024px) {
        .AboutUs_Title {
            font-size: 60px;
        }
    }

    @media (max-width: 767px) {        

        .AboutUs_Title {
            font-size: 45px;
        }

        .AboutUs_Desc{
            font-size: 17px;
        }
    }



  
    
    