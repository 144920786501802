@import url('https://fonts.cdnfonts.com/css/tt-backwards-sans-trial');
@import url('https://fonts.cdnfonts.com/css/tt-commons');

/**/
.textile-section {
  color: #6c6c6c;
}

.title {
  font-family: 'TT Backwards Sans Trial', sans-serif;
  font-size: 72px;
  color: #6c6c6c;
  font-weight: 400;
}

.intro {
  font-family: 'TT Commons Pro', sans-serif;
  font-size: 20px;
  line-height: 1.8;
  margin-top: 0;
  color: #6c6c6c;
  text-align: justify;
}

.subtitle {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #6c6c6c;
    font-family: 'TT Commons Pro', sans-serif;
}

.accessory-list {
    list-style-type: disc;
    font-family: 'TT Commons Pro', sans-serif;
    font-size: 20px;
    line-height: 1.8;
    margin-top: 0;
    color: #6c6c6c;
    text-align: justify;
}

.accessory-list li {
    font-size: 18px;
    line-height: 1.6;
}

.description1 {
  font-family: 'TT Commons Pro', sans-serif;
  font-size: 20px;
  line-height: 1.8;
  margin-top: 0;
  color: #6c6c6c;
  text-align: justify;
}
  
  .margens-services {
    margin: 0 10%;
    overflow: hidden; /* Adiciona overflow hidden para garantir que o conteúdo não saia do contêiner */
    padding-top: 5em;
  }
  
  /* Row de itens no card */
  .row-1 {
    display: flex;
    justify-content: space-between;
    padding-top: 3em;
    padding-bottom: 2em;
    flex-wrap: wrap;
    overflow: hidden; /* Oculta qualquer conteúdo que ultrapasse os limites do contêiner */
  }
  
  .card-image {
    position: relative;
    width: 31.3%; /*Define tamanho da div, neste caso da imagem*/
    overflow: hidden;
    margin-bottom: 3%;
  }
  
  .card-image img {
    width: 100%; /*Ocupa 100% da div pai (card-image)*/
    display: block; /* Garante que a imagem seja um bloco */
  }
  
  .description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    display: none;
  }
  
  .description p {
    color: #fff;
    font-family: 'TT Commons Pro', sans-serif;
    
  }

  .card-image:hover{
    transition: .5s;
    scale: 1.04;
  }

  .description h3 {
    color: #fff;
    font-family: 'TT Commons Pro', sans-serif;
    
  }
  
  .card-image:hover .description {
    display: flex;
    cursor: pointer;
    transition: .5s;
    scale: 1.1;
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
  
    .margens-services {
      padding-top: 7em;
    }

    .card-image {
      width: 45%;
      margin: 10px auto;
    }

    .title{
      font-size: 60px;
    }

  }
  
  @media (max-width: 767px) {
    .row-1 {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .card-image {
      width: 90%;
      margin: 10px auto;
    }
  
    .card-content {
      margin-bottom: 10%;
    }
  
    .card-title h2 {
      margin-left: 5%;
    }
  
    .margens-services {
      padding-top: 3em;
    }

    .title {
      font-size: 45px;
    }

    .Textile_Desc {
      font-size: 17px;
    }

    .intro {
      font-size: 17px;
    }
    
    .subtitle {
        font-size: 20px;
    }
    
    .accessory-list li {
        font-size: 17px;
        line-height: 1.6;
    }
    
    .description1 {
      font-size: 17px;
    }

  }

  @media (min-width: 1540px) {
    .margens-services {
      padding-top: 6em;
    }

    
  }
  


 


